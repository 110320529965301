<template>
    <div>
      <div class="wrapper innerBg">
      <!--header-->
      <div class="header">
        <div class="pagesize">
          <div class="headerNav">
            <router-link class="headerlogo" to="./index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
            <div class="state web">
              <div class="circle"></div>通訊正常
            </div>
            <ul class="headerNav_menu web">
              <li><router-link to="./index">首頁</router-link></li>
              <li><router-link to="./public">公共用電</router-link></li>
              <li><router-link class="on" to="./floor">樓層用電</router-link></li>
            <li><router-link to="./ice" >冰水主機</router-link></li>
            <li><router-link to="./solar" >光電系統</router-link></li>
            <li><router-link to="./download" >下載專區</router-link></li>
            </ul>
            <div class="menuic mobile btn_menu">
              <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
              <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
            </div>
          </div>
        </div>
        <!--小網menu-->
        <div class="open_nav">
          <ul>
            <li class="m_nav"><router-link to="/index">首頁</router-link></li>
            <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
            <li class="m_nav"><router-link to="./floor" >樓層用電</router-link></li>
            <li class="m_nav"><router-link to="./ice" >冰水主機</router-link></li>
            <li class="m_nav"><router-link to="./solar" >光電系統</router-link></li>
            <li class="m_nav"><router-link to="./download" >下載專區</router-link></li>
          </ul>
          <div class="state">
            <div class="circle"></div>通訊正常
          </div>
        </div>
      </div>
      <!--內容開始-->
      <div class="contain">
        <div class="pagesize">
          <div class="main">
            <div class="lsDate">最後更新時間 {{upDateTime}}</div>
            <div class="mgt">
            <div class="graph graphM mgb">
              <p>用電資訊</p>
              <!-- table -->
              <div class="tableframe">
                <table class="table_rwd">
                  <tr class="table_rwd_th">
                    <th></th>
                    <th class="tR">即時用電量</th>
                    <th class="tR">本日/本月累積用電量</th>
                    <th class="tR">本日各時累積用電量</th>
                  </tr>
                  <tr>
                    <td class="tL">
                      <div class="floorIMG"><img src="./images/floor/01.png"></div>
                      <div class="floor">
                        <div class="floorTit">地下樓層</div>
                        <div class="floorNum">16-17-5800-04-0</div>
                      </div>
                    </td>
                    <td data_th="即時用電量" class="tR">{{floorCurrentKW['METER_B1B2']}}<span> kW</span></td>
                    <td data_th="本日/本月累積用電量" class="tR">{{floorDayKWh['METER_B1B2']}} / {{floorMonKWh['METER_B1B2']}}<span> kWh</span></td>
                    <td data_th="本日各時累積用電量">
                      <div class="floorGraph">
                        <Highcharts :options="underFloorChart" ref="highchart" />
                        <!-- <img src="./images/floor/graph1.png"> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="tL">
                      <div class="floorIMG"><img src="./images/floor/02.png"></div>
                      <div class="floor">
                        <div class="floorTit">
                          <div class="blue">樓層七</div>
                        </div>
                        <div class="floorNum">16-17-5800-70-1</div>
                      </div>
                    </td>
                    <td data_th="即時用電量" class="tR">{{floorCurrentKW['METER_7F']}}<span> kW</span></td>
                    <td data_th="本日/本月累積用電量" class="tR">{{floorDayKWh['METER_7F']}} / {{floorMonKWh['METER_7F']}}<span> kWh</span></td>
                    <td data_th="本日各時累積用電量">
                      <div class="floorGraph">
                        <Highcharts :options="floorSevenChart" ref="highchart" />
                        <!-- <img src="./images/floor/graph2.png"> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="tL">
                      <div class="floorIMG"><img src="./images/floor/03.png"></div>
                      <div class="floor">
                        <div class="floorTit">
                          <div class="Dorange">樓層八</div>
                        </div>
                        <div class="floorNum">16-17-5800-81-4</div>
                      </div>
                    </td>
                    <td data_th="即時用電量" class="tR">{{floorCurrentKW['METER_8F']}}<span> kW</span></td>
                    <td data_th="本日/本月累積用電量" class="tR">{{floorDayKWh['METER_8F']}} / {{floorMonKWh['METER_8F']}}<span> kWh</span></td>
                    <td data_th="本日各時累積用電量">
                      <div class="floorGraph">
                        <Highcharts :options="floorEightChart" ref="highchart" />
                        <!-- <img src="./images/floor/graph3.png"> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="tL">
                      <div class="floorIMG"><img src="./images/floor/04.png"></div>
                      <div class="floor">
                        <div class="floorTit">
                          <div class="Dgreen">樓層九</div>
                        </div>
                        <div class="floorNum">16-17-5800-91-6</div>
                      </div>
                    </td>
                    <td data_th="即時用電量" class="tR">{{floorCurrentKW['METER_9F']}}<span> kW</span></td>
                    <td data_th="本日/本月累積用電量" class="tR">{{floorDayKWh['METER_9F']}} / {{floorMonKWh['METER_9F']}}<span> kWh</span></td>
                    <td data_th="本日各時累積用電量">
                      <div class="floorGraph">
                        <Highcharts :options="floorNineChart" ref="highchart" />
                        <!-- <img src="./images/floor/graph4.png"> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="tL">
                      <div class="floorIMG"><img src="./images/floor/05.png"></div>
                      <div class="floor">
                        <div class="floorTit">
                          <div class="Dred">樓層十</div>
                        </div>
                        <div class="floorNum">16-17-5800-71-2</div>
                      </div>
                    </td>
                    <td data_th="即時用電量" class="tR">{{floorCurrentKW['METER_10F']}}<span> kW</span></td>
                    <td data_th="本日/本月累積用電量" class="tR">{{floorDayKWh['METER_10F']}} / {{floorMonKWh['METER_10F']}}<span> kWh</span></td>
                    <td data_th="本日各時累積用電量">
                      <!-- <Highcharts :options="floorTenChart" ref="highchart" /> -->
                      <div class="floorGraph">
                        <Highcharts :options="floorTenChart" ref="highchart" />
                        <!-- <img src="./images/floor/graph5.png"> -->
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            </div>
            <div class="graph graphM">
              <p>用電歷史資訊</p>
              <div class="graphBar">
                <select class="wL mgr" v-model="selectFloor">
                  <option value='METER_B1B2'>地下樓層 16-17-5800-04-0</option>
                  <option value='METER_7F'>樓層七 16-17-5800-70-1</option>
                  <option value='METER_8F'>樓層八 16-17-5800-81-4</option>
                  <option value='METER_9F'>樓層九 16-17-5800-91-6</option>
                  <option value='METER_10F'>樓層十 16-17-5800-71-2</option>
                </select>
                <div class="interval">
                  <input type="checkbox" id="c1" name="yesOrNo" v-model="selectCheck">
                  <label for="c1">選擇區間<span></span></label>
                </div>
                <div class="btn_date">
                  <a @click="dateTypeSelect('date')" :class="{ dateActive: DateType === 'date'}">日</a>
                  <a @click="dateTypeSelect('month')" :class="{ dateActive: DateType === 'month'}">月</a>
                  <a @click="dateTypeSelect('year')" :class="{ dateActive: DateType === 'year'}">年</a>
                </div>
                <div class="interCa">
                  <div class="inputCa wM">
                    <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" :type="DateType" valueType="format"></date-picker>
                    <!-- <input type="text" value="" placeholder="起始日期">
                    <button class="calendar"></button> -->
                  </div>
                  <p class="dib" v-if="selectCheck">至</p>
                  <div class="inputCa wM" v-if="selectCheck">
                    <date-picker style="width:auto" placeholder="結束日期" v-model="endDate" :type="DateType" valueType="format"></date-picker>
                    <!-- <input type="text" value="" placeholder="結束日期">
                    <button class="calendar"></button> -->
                  </div>
                </div>
                <div class="btn_confirm dib">
                  <a @click="historyQuery(DateType)">確認</a>
                </div>
              </div>
              <Highcharts :options="selectFloorEtodayChart" ref="highchart" />
              <!-- <img class="web" src="./images/graph5.png">
              <img class="mobile" src="./images/graph5_m.png"> -->
            </div>
          </div>
        </div>
      </div>
      <!--footer-->
      <footer>
        <div class="pagesize">
          <div class="ftLogo"><img src="./images/logo2.png" alt="">
          </div>
          <div class="copyright">
            Copyright © All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
    </div>
</template>

<style scoped>
  @import './css/global.css';
  a {
    cursor: pointer;
  }
  .dateActive {
    background-color: #128771;
    color: white
  }
  .inputW {
    width: 170px;
  }
</style>

<script>
import importJQ from './js/common'
import $ from 'jquery'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapActions, mapState, mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import { DateTime } from 'luxon'
import VueCookies from 'vue-cookies'
import axios from 'axios'
export default {
  components: { DatePicker },
  data () {
    return {
      lowMeterKw: '',
      selectCheck: false,
      DateType: 'date',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      memberInfoShow: false,
      selectFloor: 'METER_B1B2',
      FloorEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 115
          // width: 500
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 20
        },
        plotOptions: {
          line: {
            dataLabels: {
              // enabled: true
            }
            // enableMouseTracking: false
          }
        },
        series: [{
          name: '用電量(度)',
          data: [],
          color: '#4cc0e2'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      },
      selectFloorEtodayChart: {
        chart: {
          type: 'line',
          backgroundColor: null
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        plotOptions: {
          line: {
            dataLabels: {
              // enabled: true
            }
            // enableMouseTracking: false
          }
        },
        series: [{
          name: '用電量(度)',
          data: [],
          color: '#4cc0e2'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      },
      underFloorChart: {},
      floorSevenChart: {},
      floorEightChart: {},
      floorNineChart: {},
      floorTenChart: {},
      floorCurrentKW: {
        METER_7F: 0,
        METER_8F: 0,
        METER_9F: 0,
        METER_10F: 0,
        METER_B1B2: 0
      },
      floorDayKWh: {
        METER_7F: 0,
        METER_8F: 0,
        METER_9F: 0,
        METER_10F: 0,
        METER_B1B2: 0
      },
      floorMonKWh: {
        METER_7F: 0,
        METER_8F: 0,
        METER_9F: 0,
        METER_10F: 0,
        METER_B1B2: 0
      }
    }
  },
  methods: {
    ...mapMutations([
      'GETUPDATE_TIME'
    ]),
    ...mapActions([
      'getdayData',
      'getHourData',
      'getDayInterval',
      'getMonData',
      'getLastMerged',
      'getMonInterval',
      'getYearInterval'
    ]),
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    eachFloorChartInherit () {
      this.underFloorChart = JSON.parse(JSON.stringify(this.FloorEtodayChart))
      this.floorSevenChart = JSON.parse(JSON.stringify(this.FloorEtodayChart))
      this.floorEightChart = JSON.parse(JSON.stringify(this.FloorEtodayChart))
      this.floorNineChart = JSON.parse(JSON.stringify(this.FloorEtodayChart))
      this.floorTenChart = JSON.parse(JSON.stringify(this.FloorEtodayChart))
    },
    eachFloorHourChart () {
      const floorArray = [this.underFloorChart, this.floorSevenChart, this.floorEightChart, this.floorNineChart, this.floorTenChart]
      const floor = ['METER_B1B2', 'METER_7F', 'METER_8F', 'METER_9F', 'METER_10F']
      for (let i = 0; i < this.EhourData.data.length; i++) {
        for (let j = 0; j < this.EhourData.data[i].detail.floor.length; j++) {
          for (let k = 0; k < floor.length; k++) {
            if (this.EhourData.data[i].detail.floor[j].floor === floor[k]) {
              floorArray[k].xAxis.categories.push((this.EhourData.data[i].hhmm).slice(0, 2))
              floorArray[k].series[0].data.push(this.handleNum(this.EhourData.data[i].detail.floor[j].kWh))
            }
          }
        }
      }
    },
    async eachFloorCurrentKW () {
      try {
        await this.getlowMeterKw()
        if (this.lowMeterKw.success) {
          for (let i = 0; i < this.lowMeterKw.data.length; i++) {
            this.floorCurrentKW[`${this.lowMeterKw.data[i].floor}`] = this.handleNum(this.lowMeterKw.data[i].kW)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async eachFloorDaydataAndMondata () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
        endDate: DateTime.local().toFormat('yyyy-MM-dd')
      }
      await this.getDayInterval(date)
      await this.getMonData(DateTime.local().toFormat('yyyy'))
      if (this.InterData.success) {
        for (let i = 0; i < this.InterData.data[0].detail.floor.length; i++) {
          this.floorDayKWh[`${this.InterData.data[0].detail.floor[i].floor}`] = this.handleNum(this.InterData.data[0].detail.floor[i].kWh)
        }
      }
      if (this.EmonData.success) {
        const lastMon = this.EmonData.data.length - 1
        for (let i = 0; i < this.EmonData.data[lastMon].detail.floor.length; i++) {
          this.floorMonKWh[`${this.EmonData.data[lastMon].detail.floor[i].floor}`] = this.handleNum(this.EmonData.data[lastMon].detail.floor[i].kWh)
        }
      }
    },
    dateTypeSelect (str) {
      this.DateType = str
      if (str === 'date') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM-dd')
        this.endDate = DateTime.local().toFormat('yyyy-MM-dd')
      }
      if (str === 'month') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM')
        this.endDate = DateTime.local().toFormat('yyyy-MM')
      }
      if (str === 'year') {
        this.beginDate = DateTime.local().toFormat('yyyy')
        this.endDate = DateTime.local().toFormat('yyyy')
      }
    },
    async historyQuery (type) {
      if (!this.selectCheck) {
        if (type === 'date') {
          await this.getHourData(this.beginDate)
          if (this.EhourData.success) {
            this.selectFloorChart(this.EhourData)
          }
        } else if (type === 'month') {
          await this.getdayData(this.beginDate)
          if (this.EdayData.success) {
            this.selectFloorChart(this.EdayData)
          }
        } else if (type === 'year') {
          await this.getMonData(this.beginDate)
          if (this.EmonData.success) {
            this.selectFloorChart(this.EmonData)
          }
        }
      } else {
        const date = {
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        if (type === 'date') {
          await this.getDayInterval(date)
          if (this.InterData.success) {
            this.selectFloorChart(this.InterData)
          }
        } else if (type === 'month') {
          await this.getMonInterval(date)
          if (this.MonInterData.success) {
            this.selectFloorChart(this.MonInterData)
          }
        } else if (type === 'year') {
          await this.getYearInterval(date)
          if (this.YearInterData.success) {
            this.selectFloorChart(this.YearInterData)
          }
        }
      }
    },
    selectFloorChart (arr) {
      this.selectFloorEtodayChart.series[0].data = []
      this.selectFloorEtodayChart.xAxis.categories = []
      for (let i = 0; i < arr.data.length; i++) {
        if (arr.data[i].hhmm) {
          this.selectFloorEtodayChart.xAxis.categories.push(arr.data[i].hhmm)
        } else if (arr.data[i].date) {
          this.selectFloorEtodayChart.xAxis.categories.push(arr.data[i].date)
        }
        for (let j = 0; j < arr.data[i].detail.floor.length; j++) {
          if (arr.data[i].detail.floor[j].floor === this.selectFloor) {
            this.selectFloorEtodayChart.series[0].data.push(this.handleNum(arr.data[i].detail.floor[j].kWh))
          }
        }
      }
    },
    async getlowMeterKw () {
      const token = VueCookies.get('Authorization')
      const lowMeterKw = await axios.post('/api/ems/shilin/lowMeterKw',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (lowMeterKw.data.success) {
        this.lowMeterKw = lowMeterKw.data
      }
    }
  },
  async mounted () {
    this.eachFloorChartInherit()
    this.eachFloorCurrentKW()
    await importJQ()
    await this.getHourData()
    this.eachFloorDaydataAndMondata()
    this.eachFloorHourChart()
    this.historyQuery(this.DateType)
    this.getlowMeterKw()
    this.GETUPDATE_TIME()
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'upDateTime',
      'EdayData',
      'EhourData',
      'InterData',
      'EmonData',
      'lastCurrentData',
      'MonInterData',
      'YearInterData'
    ])
  }
}
</script>
